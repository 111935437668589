import React, { useEffect, useState, lazy, Suspense } from 'react';
import Lottie from 'react-lottie';
//import { TweenMax, Power3 } from 'gsap'

import lottieLoader from './assets/warp_loader.json'

import './App.scss';
const Main = lazy(() => import('./pages/Main/Main'))

const App = () => {
  
  //const [loader, setLoader] = useState(false)
  //const [refresh, setRefresh] = useState(false)
  //let app = useRef(null)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  // useEffect(() => {
  //   try {
  //     const data = sessionStorage.getItem("page-refresh")
  //     if(data === "true") {
  //       setRefresh(true) 
  //       setLoader(true)
  //     }
  //     else {
  //       sessionStorage.setItem("page-refresh", JSON.stringify(true))
  //     }
  //   } catch (error) {
  //       console.error("Denied access to Session Storage", error)
  //   }
  // }, [])

  // useEffect(() => {
  //   if(!refresh) {
  //     setTimeout(() => {
  //         setLoader(!loader);
  //     }, 5000)
  //   }
  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   if(!refresh) {
  //     TweenMax.to(
  //       app,
  //       1.75,
  //       {
  //         opacity: 0,
  //         ease: Power3.easeOut,
  //         delay: 4,
  //       }
  //     )}
  //   // eslint-disable-next-line
  // }, []);

  const useDelayedRender = delay => {
    const [delayed, setDelayed] = useState(true);
    useEffect(() => {
      const timeout = setTimeout(() => setDelayed(false), delay);
      return () => clearTimeout(timeout);
      // eslint-disable-next-line
    }, []);
    return fn => !delayed && fn();
  };

  const DelayedRender = ({ delay, children }) => useDelayedRender(delay)(() => children);

  return (
    <div className="app">
      <Suspense 
        fallback={
          <DelayedRender delay={500}>
            <div className="app__lotti" >
            <Lottie 
              options={defaultOptions}
              height={400}
              width={400}
            />
            </div>
          </DelayedRender>
        }
      >
        <Main /> 
      </Suspense>
    </div>
  );
}

export default App;
